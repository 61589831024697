import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Connect } from '../classes/connect';
import { Anagrafica } from '../main/main-home/pages/anagrafica/interfaces/anagrafica';
import { Commessa } from '../main/main-home/pages/lavorazioni/commesse/interfaces/commessa';
import { AnacliforproCorsista } from '../main/main-home/pages/anagrafica/interfaces/anacliforpro-corsista';
import { Anagraficacliforpro } from '../main/main-home/pages/anagrafica/interfaces/anagraficacliforpro';
import { Comune } from '../interfaces/comune';
import { ConnectServerService } from './connect-server.service';
import { SocioVol } from '../main/main-home/pages/soci-volontari/interfaces/socio-vol';
import { Articolo } from '../main/main-home/pages/magazzino-articoli/interfaces/articolo';

@Injectable({
  providedIn: 'root'
})
export class SearchAutocompleteService {

  constructor(private connectServerService: ConnectServerService) {
  }
/*
  getAnagrafica(valore?: string): Observable<Post[]> {
      return this.http.get<Post[]>('https://jsonplaceholder.typicode.com/posts' + valore);
  }*/


  /**
   * Barra di ricerca articoli
   * @param tipoRicerca: 1 codice, 2 descrizione
   * @param valore
   * @param listino: 0 NO, 1 SI
   * @param qualidati:  ONLY: solo il listino, se non c'è listino prendo tutti gli articoli,
   *                    AND: tutti gli articoli con i dati del listino se presenti nel listino altrimenti dati generali dell'articolo
   * @param idlistino: se 0 prendo l'ultimo (altrimenti passare id listino)
   * @param idanacliforpro
   * @returns
   */
  getArticoli(tipoRicerca: number, valore?: string, listino=0, qualidati='', idlistino=0, idanacliforpro=0): Observable<Articolo[]> {
      return this.connectServerService.getRequestLara<Articolo[]>(Connect.urlServerLaraApi, 'articoli/searchArticoli',
          {
              tipo: tipoRicerca,
              val: valore,
              listino: listino,
              qualidati: qualidati,
              idlistino: idlistino,
              idanacliforpro: idanacliforpro
          });
  }

  getSocioVol(tipoRicerca: string, valore?: string): Observable<SocioVol[]> {
      return this.connectServerService.getRequestLara<SocioVol[]>(Connect.urlServerLaraApi, 'auth/searchSocioVol',
          {
              tipo_ricerca: tipoRicerca,
              val: valore
          });
  }

  // barra di ricerca comuni
  getComuni(tipoRicerca: number, valore?: string): Observable<Comune[]> {
      return this.connectServerService.getRequestLara<Comune[]>(Connect.urlServerLaraApi, 'infoworld/searchMunicipalities',
          {
              tipo: tipoRicerca,
              val: valore
          });
  }

  // barra di ricerca cliforpro
  /**
   * @param mittente_: cliente, fornitore, prospect o all
   * @param tipoRicerca 0 nome,cognome o ragionesociale, 1 CF o P.iva
   * @param valore
   * @returns
   */
   getAnaCliForPro(mittente_: string, tipoRicerca: number, valore?: string): Observable<Anagraficacliforpro[]> {
      return this.connectServerService.getRequestLara<Anagraficacliforpro[]>(Connect.urlServerLaraApi, 'anagrafica/searchAnaCliForPro',
          {
              mittente: mittente_,
              tipo: tipoRicerca,
              val: valore
          });
  }
// barra di ricerca cliforprocontatti
  /**
   * @param mittente_: cliente, fornitore, prospect o all
   * @param tipoRicerca 0 nome,cognome o ragionesociale, 1 CF o P.iva
   * @param valore
   * @returns
   */
  getAnaCliForProContatti(mittente_: string, tipoRicerca: number, valore?: string): Observable<AnacliforproCorsista[]> {
      return this.connectServerService.getRequestLara<AnacliforproCorsista[]>(Connect.urlServerLaraApi, 'auth/searchAnaCliForProCorsisti',
          {
              mittente: mittente_,
              tipo: tipoRicerca,
              val: valore
          });
  }
   /**
   *
   * @param tipoRicerca 0 nome e/o cognome, 1 CF
   * @param valore
   * @returns
   */
    getAnagrafica(tipoRicerca: number, valore?: string): Observable<Anagrafica[]> {
      return this.connectServerService.getRequestLara<Anagrafica[]>(Connect.urlServerLaraApi, 'auth/searchAnagrafica',
          {
              tipo: tipoRicerca,
              val: valore
          });
  }

  /**
   *
   * @param tipoRicerca: progressivo, serialnumber
   * @param valore
   * @returns
   */
  getCommessa(tipoRicerca: string, valore?: string): Observable<Commessa[]> {
      return this.connectServerService.getRequestLara<Commessa[]>(Connect.urlServerLaraApi, 'lavorazioni/searchCommessa',
          {
              tipo: tipoRicerca,
              val: valore
          });
  }
}
